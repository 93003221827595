// src/pages/WhyChooseUs.js
import React from 'react';
import './WhyChooseUs.css'; // Ensure your CSS file path is correct

const WhyChooseUs = ({ backgroundImage }) => {
  return (
    <div className="why-choose-us-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h2>Why Choose Us</h2>
      <p>At Smilefemme Healthcare Clinic, we prioritize your health and well-being above all. Our experienced team of professionals is dedicated to providing personalized care tailored to your needs.</p>
      <h3>Here are a few reasons why our clinic stands out:</h3>
      <ul>
        <li><span role="img" aria-label="star"> 🌟 </span> <strong>Experienced Professionals:</strong> Our team has extensive expertise in various dental and skin & hair procedures.</li>
        <li><span role="img" aria-label="rainbow">🌈 </span> <strong>Comprehensive Care:</strong> We offer a wide range of services, including dental care, skin treatments, and hair solutions.</li>
        <li><span role="img" aria-label="stethoscope">🩺 </span> <strong>State-of-the-art Facilities:</strong> We utilize the latest technology for accurate diagnoses and effective treatments.</li>
        <li><span role="img" aria-label="calendar">📅 </span><strong>Flexible Scheduling:</strong> We provide convenient appointment options to fit your busy schedule.</li>
        <li><span role="img" aria-label="chat">💬 </span><strong>Transparent Communication:</strong> We ensure you are informed and involved in every step of your treatment.</li>
      </ul>
      
    </div>
  );
}

export default WhyChooseUs;
