import React from 'react';
import './Doctors.css'; // Import styling for the Doctors component
import DocVS from './images/Doc_VS.jpg'; // Import images properly
import DocPS from './images/Doc_PS.jpg';
import DocPB from './images/Doc_PB.jpg';
import DocMS from './images/Doc_MS.jpg';
import DocNS from './images/NS.jpg';
import DocSA from './images/DocSA.jpg';
import DocSAh from './images/Doc_SAh.jpg';
import DocPBh from './images/DocPBh.jpeg'
const doctorsData = [
  {
    name: "Dr. Varun Suhag",
    qualification: "BDS, MDS",
    description: "Consultant Periodontist & Oral Implantologist",
    image: DocVS // Use imported image here
  },
  {
    name: "Dr. Monica Chaudhry",
    qualification: "BDS, MDS, PhD (Health Sciences)",
    description: "Consultant Public Health Dentist & Research Scientist, Fellowship in Reasearch and Training to Address Chronic Conditions",
    image: DocMS // Use imported image here
  },
  {
    name: "Dr. Priyanka Suhag",
    qualification: "MBBS, DGO, DNB, MNAMS",
    description: "Consultant Obstetrics and Gynecology, Fellowship in Aesthetic Medicine, Fellowship in minimal Invasive Gynecology",
    image: DocPS // Use imported image here
  },
  {
    name: "Dr. Paras Bhardwaj",
    qualification: "MD, DCH",
    description: "Consultant Child Specialist and Neonatologist, Diploma in Pediatric Asthma and Allergy",
    image: DocPB // Use imported image here
  },
  {
    name: "Dr. Neha Sachdeva",
    qualification: "BDS, MDS, FICR",
    description: "Consultant Prosthodontist & Oral Implantologist",
    image: DocNS // Use imported image here
  },

  {
    name: "Dr. Sana Manzoor Ahmed",
    qualification: "BDS, MPH",
    description: "Consultant Dental surgeon & Tobacco Cessation Expert",
    image: DocSA // Use imported image here
  },

  {
    name: "Dr. Saima Ahmad",
    qualification: "BDS,CC, Forensic Odontology",
    description: "Consultant Dental Surgeon & Certified Forensic Odontologist",
    image: DocSAh // Use imported image here
  },

  {
    name: "Dr. Pratyush Bhatt",
    qualification: "BDS,MSC,MIDA",
    description: "Consultant Oro-Dental Surgeon",
    image: DocPBh // Use imported image here
  },
  // Add more doctors as needed
];

const Doctors = () => {
  return (
    <div className="doctors-section">
      <h2>Our Doctors</h2>
      <div className="doctors-list">
        {doctorsData.map((doctor, index) => (
          <div className="doctor-card" key={index}>
            <img src={doctor.image} alt={doctor.name} className="doctor-image" />
            <h3>{doctor.name}</h3>
            <p className="qualification">{doctor.qualification}</p>
            {/* Split description by periods and map them to individual lines */}
            <p className="description">
              {doctor.description.split(',').map((desc, idx) => (
                desc && <span key={idx}>{desc.trim()}<br /></span>
              ))}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Doctors;
