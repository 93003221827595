import React, { useState } from 'react';
import './Services.css';

const Services = () => {
    const [activeService, setActiveService] = useState(null);
    const [activeSubcategory, setActiveSubcategory] = useState(null);

    const services = [
        {
            title: "DENTAL SERVICES",
            items: [
                { title: "RCT", description: "Root Canal Treatment to save and restore decayed teeth." },
                { title: "CROWNS AND BRIDGES", description: "Restoration of damaged or missing teeth with dental crowns and bridges." },
                { title: "SCALING (CLEANING) & POLISHING", description: "Professional teeth cleaning and polishing for healthier gums." },
                { title: "DENTAL IMPLANTS", description: "Permanent solution to replace missing teeth with titanium implants." },
                { title: "PAINLESS TOOTH EXTRACTION", description: "Safe and painless removal of problematic teeth." },
                { title: "ORTHODONTIC BRACES & ALIGNERS", description: "Straightening teeth with braces or clear aligners for a beautiful smile." },
                { title: "DENTURES", description: "Custom-made removable prosthesis to replace missing teeth." },
                { title: "DENTAL FILLINGS", description: "Restoration of cavities with tooth-colored fillings." },
                { title: "TEETH WHITENING (BLEACHING)", description: "Brightening and whitening your smile with professional treatments." },
                { title: "VENEERS & LAMINATES", description: "Custom veneers and laminates to enhance the appearance of your teeth." },
                { title: "GUM SURGERY", description: "Surgical treatment for gum-related issues and diseases." }
            ],
        },
        {
            title: "HAIR SERVICES",
            items: [
                { title: "LASER HAIR REDUCTION", description: "Permanent hair removal using advanced laser technology." },
                { title: "HAIR THREADS", description: "A non-surgical procedure using threads to lift and tighten sagging hair follicles, promoting hair rejuvenation." },
                { title: "HAIR TRANSPLANT", description: "Restoring hair through transplantation of healthy follicles." },
                { title: "HAIR REJUVENATION", description: "Non-surgical treatments to revitalize thinning hair." },
            ],
        },
        {
            title: "SKIN SERVICES",
            items: [
                { title: "BOTOX", description: "Non-invasive treatment to reduce wrinkles and fine lines." },
                { title: "CHEMICAL PEELS", description: "Exfoliating treatment to improve skin tone and texture." },
                { title: "LASER LIPOLYSIS", description: "Minimally invasive procedure to target and reduce body fat." },
                { title: "TATTOO REMOVAL", description: "Safe and effective removal of unwanted tattoos using laser technology." },
                { title: "PIGMENTATION REDUCTION", description: "Treatments to reduce pigmentation and achieve a more even skin tone." },
                { title: "HYDRAFACIAL", description: "Hydration and deep-cleansing facial treatment for glowing skin." },
                { title: "PRP", description: "Platelet-Rich Plasma therapy for skin rejuvenation and hair growth." }
            ],
        },
        {
            title: "GYNAE SERVICES", // New GYNAE section added
            items: [
                { title: "ANTENATAL CHECK-UP", description: "Comprehensive care for expectant mothers." },
                { title: "NORMAL DELIVERY / LSCS", description: "Assistance with normal and cesarean deliveries." },
                { title: "DIABETES WITH PREGNANCY", description: "Managing diabetes during pregnancy." },
                { title: "RH NEGATIVE BLOOD GROUP WITH PREGNANCY", description: "Care for Rh-negative blood group pregnancies." },
                { title: "HYPERTENSION IN PREGNANCY", description: "Monitoring and managing high blood pressure during pregnancy." },
                { title: "ANEMIA WITH PREGNANCY", description: "Treatment for anemia in pregnant women." },
                { title: "BAD OBSTETRICAL HISTORY", description: "Care for women with a history of pregnancy complications." },
                { title: "MTP, FAMILY PLANNING SERVICES", description: "Family planning and medical termination of pregnancy services." },
                { title: "GYNAECOLOGY OPD", description: "Comprehensive outpatient care for women." },
                { title: "INFERTILITY OPD", description: "Outpatient services for managing infertility." },
                { title: "OPEN SURGERY - CYSTECTOMY, MYOMECTOMY, HYSTERECTOMY", description: "Surgical interventions for gynecological conditions." },
                { title: "HYSTEROSCOPIC SURGERY", description: "Minimally invasive hysteroscopic procedures." },
                { title: "LAPROSCOPIC SURGERY - CYSTECTOMY, MYOMECTOMY, HYSTERECTOMY", description: "Laparoscopic surgeries for various gynecological needs." }
            ],
        },
        {
            title: "PEDIATRIC SERVICES",
            items: [
                { title: "VACCINATION", description: "Scheduled immunizations to protect children from various diseases." },
                { title: "NEBULIZATION", description: "Treatment for respiratory conditions using a nebulizer for easier breathing." },
                { title: "OPD (Outpatient Department)", description: "Consultation and treatment for pediatric health concerns." },
                { title: "IPD (Inpatient Department)", description: "Comprehensive inpatient care for children requiring hospitalization." },
                { title: "CPAP (Continuous Positive Airway Pressure Therapy)", description: "Therapy for infants with breathing difficulties using CPAP machines." },
            ],
        },
    ];

    const handleServiceClick = (index) => {
        setActiveService(activeService === index ? null : index); // Toggle service
        setActiveSubcategory(null); // Reset subcategory when toggling service
    };

    const handleSubcategoryClick = (serviceIndex, itemIndex) => {
        if (activeService === serviceIndex) {
            setActiveSubcategory(activeSubcategory === itemIndex ? null : itemIndex); // Toggle subcategory
        } else {
            setActiveService(serviceIndex); // Activate service if not already active
            setActiveSubcategory(itemIndex); // Set subcategory to active
        }
    };

    return (
        <div className="services-container">
            <h2 className="services-title">Our Services</h2>
            {services.map((service, serviceIndex) => (
                <div key={serviceIndex} className="service-card" onClick={() => handleServiceClick(serviceIndex)}>
                    <h3 className="service-title">{service.title}</h3>
                    {activeService === serviceIndex && (
                        <div className="subcategory-container">
                            {service.items.map((item, itemIndex) => (
                                <div 
                                    key={itemIndex} 
                                    className={`subcategory-item ${activeSubcategory === itemIndex ? 'active' : ''}`} 
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent click from bubbling up to service card
                                        handleSubcategoryClick(serviceIndex, itemIndex);
                                    }}
                                >
                                    <h4 className="service-title">{item.title}</h4>
                                    <p className="service-description">{item.description}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Services;
