import React, { useEffect, useState } from 'react';
import './PatientTestimony.css'; // Ensure your CSS file path is correct

const API_KEY = 'AIzaSyCSqVsDhZVVR5e0RVz7-iXhaT84Az7YlZc'; // Replace with your YouTube Data API key
const CHANNEL_ID = 'UCkc1Y790UbKwwhjY7xTkY2A'; // Use just the channel ID without 'youtube.com/channel/'

const PatientTestimony = () => {
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // State for keeping track of the current video

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=5`);
        const data = await response.json();

        if (data.items) {
          const videoData = data.items
            .filter(item => item.id.kind === "youtube#video") // Ensure it's a video
            .map(item => ({
              videoId: item.id.videoId,
              // title: item.snippet.title // Get the video title
            })); // Extract video IDs and titles

          setVideos(videoData);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  // Function to handle navigating to the next video
  const nextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length); // Loop to the first video
  };

  // Function to handle navigating to the previous video
  const prevVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length); // Loop to the last video
  };

  return (
    <div className="patient-testimony-container">
      <h1>Patient Testimonies</h1>
      {videos.length > 0 && (
        <div className="video-carousel">
          <button className="carousel-btn prev" onClick={prevVideo}>&#10094;</button> {/* Previous arrow */}
          <div className="video-item">
            <h3>{videos[currentIndex].title}</h3> {/* Use the title from the YouTube video */}
            <iframe
              width="100%"
              height="400"
              src={`https://www.youtube.com/embed/${videos[currentIndex].videoId}?rel=0&showinfo=0&modestbranding=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={videos[currentIndex].title}
            ></iframe>
          </div>
          <button className="carousel-btn next" onClick={nextVideo}>&#10095;</button> {/* Next arrow */}
        </div>
      )}
    </div>
  );
}

export default PatientTestimony;
