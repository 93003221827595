import React from 'react';
import './Footer.css'; // Ensure your CSS file is styled properly
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationPin } from '@fortawesome/free-solid-svg-icons/faLocationPin';

const Footer = () => {
  return (
    <footer className="details">
      <div className="footer-left">
        <h3>Connect with Us</h3>
        <p> <FontAwesomeIcon icon={faLocationPin} size="1x" /> 50, Block G (Basement), Pushkar Enclave, Paschim Vihar, New Delhi, Delhi, 110063</p>
        <p> <FontAwesomeIcon icon={faPhone} size="1x" /> +91 9999877185 /  <FontAwesomeIcon icon={faPhone} size="1x" /> +91 9818834005 </p>
        <div className="social-icons">
          <a href="https://www.instagram.com/smilefemme9" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="https://www.youtube.com/@SmileFemmeHealthcareClinic" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
          <a href="mailto:smilefemmehealthcareclinic@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
        </div>
      </div>
      <div className="footer-right">
        <h3>Find Us Here</h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.8843537695147!2d77.09218757422425!3d28.663181182690565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03005ef943bb%3A0x1cf2ebbb56908330!2sSmileFemme%20Healthcare%20Clinic!5e0!3m2!1sen!2sin!4v1727791103092!5m2!1sen!2sin"
          width="300"
          height="200"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Map Location"
        ></iframe>
      </div>
    </footer>
  );
}

export default Footer;
