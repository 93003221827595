import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Doctors from './components/Doctors';
import Services from './components/Services';
import Footer from './components/Footer';
import WhyChooseUs from './pages/WhyChooseUs';
import PatientTestimony from './pages/PatientTestimony';
import ThankYou from './pages/ThankYou';
import CheckIn from './pages/CheckIn'; // Import the new CheckIn component
import './App.css'; // Main styling file

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Services />
              <Doctors />
            </>
          } />
          <Route path="/why-choose-us" element={<WhyChooseUs />} />
          <Route path="/PatientTestimony" element={<PatientTestimony />} />
          <Route path="/checkin" element={<CheckIn />} /> {/* Add route for Check In */}
          <Route path="/thank-you" element={<ThankYou />} /> {/* Add route for Thank You */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
