import React, { useEffect, useState } from 'react';
import './CheckIn.css'; // Import the corresponding CSS file
import clinicLogo from '../components/images/Logo.jpg';

const CheckIn = () => {
  const [iframeHeight, setIframeHeight] = useState('100%'); // Set iframe height based on window height

  useEffect(() => {
    // Adjust iframe height based on window size
    const handleResize = () => {
      const headerHeight = document.querySelector('.clinic-info').offsetHeight || 0; // Get height of the header section
      const newIframeHeight = window.innerHeight - headerHeight - 20; // Subtract some padding for safety
      setIframeHeight(`${newIframeHeight}px`);
    };
    
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16750111114/PLnOCMKkpOIZEIrTibM-',
      });
    }
    // Initial resize on load
    handleResize();

    // Add event listener to resize iframe on window resize
    window.addEventListener('resize', handleResize);

    return () => {
      // Clean up the event listener
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="checkin-container">
      <div className="clinic-info">
        <img src={clinicLogo} alt="Clinic Logo" className="clinic-logo" />
        <h2>Smilefemme Healthcare Clinic</h2>
        <p><strong>50, Block G (Basement), Pushkar Enclave, Paschim Vihar, New Delhi, Delhi - 110063</strong></p>
        <p><strong> <span role="img" aria-label="telephone">📞 </span> +91 9999877185 / <span role="img" aria-label="telephone">📞 </span> +91 9818834005</strong></p>
        <p>We recommend that patients complete their registration online to save time during their visit...</p>
      </div>

      {/* Embed Google Form */}
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSeWFEyv65kA3iLcId_UyeD4LmtsY8HL0GmpxJzMSvC5gyeY7A/viewform?embedded=true"
        className="google-form-iframe"
        frameBorder="0"
        title="Check-In Form"
        marginHeight="0"
        marginWidth="0"
        style={{ height: iframeHeight }} // Apply dynamic height based on window size
      >
        Loading…
      </iframe>
    </div>
  );
};

export default CheckIn;
