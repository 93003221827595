import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ThankYou = () => {
  const location = useLocation();
  const appointmentNumber = location.state?.appointmentNumber;

  useEffect(() => {
    // Check if `gtag` function is available, to ensure Google Analytics is loaded
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'conversion', {
        send_to: 'AW-16750111114/PLnOCMKkpOIZEIrTibM-',
      });
    }
  }, []);

  return (
    <div className="thank-you-container">
      <h1>Thank You for Checking In!</h1>
      <p>Our receptionist will contact you soon.</p>
      {appointmentNumber && (
        <p>
          Your appointment number is: <strong>{appointmentNumber}</strong>
        </p>
      )}
    </div>
  );
};

export default ThankYou;
