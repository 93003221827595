import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaUserCheck } from 'react-icons/fa';
import { MdOutlinePeopleAlt } from 'react-icons/md';
import './Header.css';
import logo from './images/Logo.jpg';

const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to handle link clicks
  const handleLinkClick = (path) => {
    setActiveLink(path); // Update the active link state
  };

  return (
    <header className="header-container">
      {/* Logo Section for Desktop */}
      <div className="logo">
        <img src={logo} alt="Clinic Logo" />
        <span><strong>Smilefemme</strong> Healthcare Clinic</span>
      </div>

      {/* Navigation Links */}
      <nav className={`nav-links ${isMobile ? 'bottom-nav' : ''}`}>
        <Link
          to="/"
          onClick={() => handleLinkClick('/')}
          className={activeLink === '/' ? 'active' : ''}
        >
          <FaHome className="nav-icon" />
          <span>Home</span>
        </Link>
        <Link
          to="/why-choose-us"
          onClick={() => handleLinkClick('/why-choose-us')}
          className={activeLink === '/why-choose-us' ? 'active' : ''}
        >
          <FaInfoCircle className="nav-icon" />
          <span>Why Choose Us</span>
        </Link>
        <Link
          to="/PatientTestimony"
          onClick={() => handleLinkClick('/PatientTestimony')}
          className={activeLink === '/PatientTestimony' ? 'active' : ''}
        >
          <MdOutlinePeopleAlt className="nav-icon" />
          <span>Testimonies</span>
        </Link>
        <Link
          to="/CheckIn"
          onClick={() => handleLinkClick('/CheckIn')}
          className={`checkin-link ${activeLink === '/CheckIn' ? 'active' : ''}`}
        >
          <FaUserCheck className="nav-icon" />
          <span>Check In</span>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
